/**
 * @category LeftSide
 * @component
 * @module Files/FileUploader
 * @description This component is part of the LeftSide and it is reponsible for file uploading and displaying
 * uploaded files.
 */
import React, { useRef, useContext, useState } from "react";
import { DashboardContext } from "../../../context/DashboardContext";
import { GraphContext } from "../../../context/GraphContext";
import { AuthContext } from "../../../context/AuthContext";
import { FileHandlingContext } from "../../../context/FileHandlingContext";
import { GeneralContext } from "../../../context/GeneralContext";
import Button from "@mui/material/Button";
import deleteIcon from "../../../res/icons/delete.png";
import {
  adjustModalPositionAndSize,
  downloadStringTxtFile,
  generateWarningObject,
  hasProperty,
  isDeepEqual,
} from "../../../utils/helpers";
import "../leftSide.scss";
import HtmlTooltip from "../../commonComponents/HtmlTooltip";
import { deepCopy } from "../Models/modelLogic";
import Modal from "react-modal";
import FileProcessingWindow from "./fileProcessing/FileProcessingWindow";
import SettingsIcon from "@mui/icons-material/Settings";
import PresetLoader from "./fileProcessing/PresetLoader";
import { processFileWithOptions } from "./fileProcessing/processingLogic";
import { readFile } from "./fileProcessing/processingLogic";
import DownloadIcon from "@mui/icons-material/Download";

const FileUploader = () => {
  const fileInputRef = useRef(null);
  const folderInputRef = useRef(null);
  const presetOptionRef = useRef(null);
  const {
    uploadedFiles,
    setUploadedFiles,
    fileID,
    setFileID,
    setWarnings,
    setNewWarningCount,
    filePresets,
    setFilePresets,
    selectedPreset,
    setSelectedPreset,
  } = useContext(DashboardContext);
  const {
    graphs,
    setGraphs,
    chi2Terms,
    setChi2Terms,
    selectedChi2Terms,
    setSelectedChi2Terms,
  } = useContext(GraphContext);
  const { currentUser, isAuthReady } = useContext(AuthContext);
  const { selectedFiles, setSelectedFiles } = useContext(FileHandlingContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const [filesToProcess, setFilesToProcess] = useState([]);
  const [fileProcessingOpen, setFileProcessingOpen] = useState(false);
  const [filePresetsOpen, setFilePresetsOpen] = useState(false);
  const [presetModalPlaceAndSize, setPresetModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  /**
   * This function is called when user selects files to upload. It filters out unsupported files and then either
   * opens the file processing window or processes the files with the selected preset.
   * @async
   * @function handleFileChange
   * @param {Event} event - This is the event that is triggered when user selects files to upload.
   */
  async function handleFileChange(event) {
    try {
      const files = Array.from(event.target.files);
      const supportedExtensions = ["txt", "dat", "TXT", "DAT"];

      const filteredFiles = files.filter((file) => {
        const fileExtension = file.name.split(".").pop();
        return supportedExtensions.includes(fileExtension);
      });

      const unsupportedFiles = files.filter(
        (file) => !filteredFiles.includes(file)
      );

      if (unsupportedFiles.length > 0) {
        const unsupportedFileNames = unsupportedFiles
          .map((file) => file.name)
          .join(", ");
        alert(
          `The following files have unsupported formats and will not be processed: ${unsupportedFileNames}`
        );
      }

      if (filteredFiles.length > 0) {
        if (selectedPreset === null) {
          setFilesToProcess(filteredFiles);
          setFileProcessingOpen(true);
        } else {
          const filesToAdd = [];
          let idToUse = fileID;

          for (let i = 0; i < filteredFiles.length; i++) {
            const file = filteredFiles[i];

            await readFile(
              file,
              generateWarningObject,
              setWarnings,
              setNewWarningCount,
              limitedToast,
              recordedErrorLog
            )
              .then((readContent) => {
                const dataPoints = processFileWithOptions(
                  readContent,
                  selectedPreset.presetOptions,
                  recordedErrorLog
                );
                if (dataPoints !== null) {
                  const maxX = dataPoints.reduce(function (max, p) {
                    const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
                    return x > max ? x : max;
                  }, Math.ceil(dataPoints[0].x));

                  const minX = dataPoints.reduce(function (min, p) {
                    const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
                    return x < min ? x : min;
                  }, Math.floor(dataPoints[0].x));

                  filesToAdd.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    lastModified: file.lastModified,
                    content: readContent,
                    dataPoints: dataPoints,
                    npoints: dataPoints.length,
                    edges: [{ min: "", max: "" }],
                    dataRangeMin: minX,
                    dataRangeMax: maxX,
                    xUnit: selectedPreset.presetOptions.xUnit,
                    ID: idToUse,
                    options: selectedPreset.presetOptions,
                  });

                  idToUse = idToUse + 1;
                } else {
                  limitedToast("File processing error.");
                  generateWarningObject(
                    `File ${
                      file && hasProperty(file, "name")
                        ? file.name
                        : "'Could-not-retrieve-the-name'"
                    } could not be processed with option preset: ${
                      selectedPreset.presetName
                    }`,
                    2,
                    setWarnings,
                    setNewWarningCount
                  );
                }
              })
              .catch(() => {
                limitedToast("Problem reading the file.");
                generateWarningObject(
                  "There was a problem reading the file: " + file && file.name
                    ? file.name
                    : "",
                  2,
                  setWarnings,
                  setNewWarningCount
                );
              });
          }

          setFileID(idToUse);
          setUploadedFiles((old) => [...old, ...filesToAdd]);
        }
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        if (folderInputRef.current) {
          folderInputRef.current.value = "";
        }
      } else {
        alert("Please select at least one .txt or .dat file.");
      }
    } catch (error) {
      recordedErrorLog("File change handler failure: ", error);
    }
  }

  /**
   * This function is called when user clicks on one of the upload buttons. It triggers the file input to open.
   * @function handleButtonClick
   * @param {string} type - This is a string that represents the type of the button that was clicked.
   */
  const handleButtonClick = (type) => {
    if (type === "file") {
      fileInputRef.current.click();
    } else if (type === "folder") {
      folderInputRef.current.click();
    }
  };

  /**
   * This function is called when user clicks on a file. It either selects the file or deselects it based on
   * whether the user is holding the Ctrl or Command key.
   * @function handleFileClick
   * @param {Event} e - This is the event that is triggered when user clicks on a file.
   * @param {File} file - This is the file that was clicked.
   * @param {number} index - This is the index of the file in the uploadedFiles array.
   */
  const handleFileClick = (e, file, index) => {
    try {
      const isMac = navigator.userAgent.indexOf("Mac") >= 0;
      const multipleSelectionKey = isMac ? e.metaKey : e.ctrlKey;

      if (multipleSelectionKey) {
        // Toggle selection when multipleSelectionKey is pressed (Ctrl or Command key)
        setSelectedFiles((prevSelectedFiles) => {
          if (prevSelectedFiles.includes({ file, index })) {
            // If the file is already selected, remove it from the selection
            return prevSelectedFiles.filter(
              (selectedFile) => selectedFile.file !== file.file
            );
          } else {
            // Otherwise, add the file to the selection
            return [...prevSelectedFiles, { file, index }];
          }
        });
      } else {
        // If the multipleSelectionKey is not pressed, select only the clicked file
        setSelectedFiles([{ file, index }]);
      }
    } catch (error) {
      recordedErrorLog("File click handler failure: ", error);
    }
  };

  /**
   * This function is called when user clicks on the delete icon of a file. It removes the file from the uploadedFiles
   * array and removes it from all graphs.
   * @function handleDeleteClick
   * @param {Event} e - This is the event that is triggered when user clicks on the delete icon.
   * @param {number} index - This is the index of the file in the uploadedFiles array.
   */
  const handleDeleteClick = (e, index) => {
    try {
      e.stopPropagation(); // Prevent triggering the file selection onClick
      // Finds the deleted file and removes it from all graphs, but doesn't remove the graphs themselves
      const fileDeleted = uploadedFiles.find((_, i) => i === index);
      const updatedGraphs = graphs.map((graph) => {
        if (graph.containedFiles.some((id) => id === fileDeleted.ID)) {
          const newFileIdArray = graph.containedFiles.filter(
            (id) => id !== fileDeleted.ID
          );
          const newPlotData = graph.plotData.filter(
            (plot) =>
              !Object.prototype.hasOwnProperty.call(plot, "fileId") ||
              plot.fileId !== fileDeleted.ID
          );

          return {
            ...graph,
            containedFiles: newFileIdArray,
            plotData: newPlotData,
          };
        } else {
          return graph;
        }
      });

      const newSelectedFiles = selectedFiles.filter(
        (selected) => selected.file.ID !== fileDeleted.ID
      );

      if (!isDeepEqual(newSelectedFiles, selectedFiles)) {
        setSelectedFiles(newSelectedFiles);
      }

      setGraphs(updatedGraphs);
      clearChi2AfterFileDelete(fileDeleted.ID);
      setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    } catch (error) {
      recordedErrorLog("Delete click handler failure: ", error);
    }
  };

  /**
   * This function clears the chi2 terms that are associated with the deleted file.
   * @function clearChi2AfterFileDelete
   * @param {number} ID - This is the ID of the file that was deleted.
   */
  function clearChi2AfterFileDelete(ID) {
    try {
      const termsCopy = deepCopy(chi2Terms);

      const updatedTerms = [];
      const remainingIDs = [];

      for (let i = 0; i < termsCopy.length; i++) {
        const term = termsCopy[i];
        if (term.fileId !== ID) {
          updatedTerms.push(term);
          remainingIDs.push(term.id);
        }
      }

      const selectedTermsUpdate = selectedChi2Terms.filter((id) =>
        remainingIDs.includes(id)
      );

      setSelectedChi2Terms(selectedTermsUpdate);
      setChi2Terms(updatedTerms);
    } catch (error) {
      recordedErrorLog("Chi2 clearing after file delete failure: ", error);
    }
  }

  /**
   * This function is called when user clicks on the close icon of the file processing window. It clears the files
   * to process and closes the window.
   * @function handleCloseProcessModal
   */
  const handleCloseProcessModal = () => {
    setFileProcessingOpen(false);
  };

  /**
   * This function is called when user clicks on the close icon of the file option preset window. It clears the
   * selected preset and closes the window.
   * @function handleClosePresetModal
   */
  const handleClosePresetModal = () => {
    setFilePresetsOpen(false);
  };

  /**
   * This function is called when user clicks on the cancel button of the file processing window. It clears the files
   * to process and closes the window.
   * @function handleCancelProcess
   */
  const handleCancelProcess = () => {
    setFilesToProcess([]);
    setFileProcessingOpen(false);
  };

  /**
   * This function is called when user clicks on the solve button of the file processing window. It adds the processed
   * files to the uploadedFiles array and closes the window.
   * @function handleSolveProcess
   * @param {object[]} fileList - This is an array of files that were processed.
   */
  const handleSolveProcess = (fileList) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...fileList]);
    setFilesToProcess([]);
    setFileProcessingOpen(false);
  };

  /**
   * This function is called when user clicks on the delete icon of a file option preset. It removes the preset from
   * the filePresets array.
   * @function handleEntryDelete
   * @param {number} id - This is the ID of the preset that was deleted.
   */
  const handleEntryDelete = (id) => {
    const clearedPresets = filePresets.filter((preset) => preset.id !== id);
    setFilePresets(clearedPresets);
  };

  /**
   * This function is called when user selects a preset from the file option preset window. It sets the selected preset
   * and closes the window.
   * @function handlePresetSelect
   * @param {number} id - This is the ID of the preset that was selected.
   */
  const handlePresetSelect = (id) => {
    const selectedPreset = filePresets.find((preset) => preset.id === id);
    setSelectedPreset(selectedPreset);
    setFilePresetsOpen(false);
  };

  /**
   * This function is called when user clicks on the clear button of the file option preset window. It clears the
   * selected preset and closes the window.
   * @function handlePresetClear
   */
  const handlePresetClear = () => {
    setSelectedPreset(null);
    setFilePresetsOpen(false);
  };

  /**
   * This function is called when user clicks to open preset options and this sets up modal location, size and that
   * it needs to be open.
   * @function handlePresetsOpen
   */
  const handlePresetsOpen = () => {
    const modalPosition = {
      top:
        presetOptionRef.current != null
          ? presetOptionRef.current.getBoundingClientRect().top
          : 0,
      right: "auto",
      left:
        presetOptionRef.current != null
          ? presetOptionRef.current.getBoundingClientRect().left + 150
          : 0,
    };
    const modalSize = { width: 350, height: 440 };

    const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

    setPresetModalPlaceAndSize(adjusted);

    setFilePresetsOpen(true);
  };

  const handleDownload = (file) => {
    try {
      const rangedData = file.dataPoints.filter(
        (point) => point.x <= file.dataRangeMax && point.x >= file.dataRangeMin
      );

      const stringifiedData = rangedData.map((point) => {
        return `${point.x}\t${point.y}`;
      });

      const singleString = stringifiedData.join("\n");

      downloadStringTxtFile(singleString, `Speqqle ${file.name}`);
    } catch (error) {
      recordedErrorLog("Error downloading data file: ", error);
    }
  };

  return (
    <div className="fileUpload">
      <div className="uploadSelection">
        {isAuthReady &&
        currentUser.email !== "demo@speqqle.com" &&
        currentUser.email !== "zbjdnmgrklqkaeumso@cwmxc.com" ? (
          <div className="uploadHeader">
            <div className="selectionTitle" ref={presetOptionRef}>
              Upload:
            </div>
            <SettingsIcon
              className="icon"
              onClick={() => handlePresetsOpen()}
            />
          </div>
        ) : (
          <div className="selectionTitle" id="restricted-data-demo">
            File loading is for premium users only.
          </div>
        )}
        {isAuthReady &&
        currentUser.email !== "demo@speqqle.com" &&
        currentUser.email !== "zbjdnmgrklqkaeumso@cwmxc.com" ? (
          <div className="uploadButtons">
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleButtonClick("file")}
              className="fileUploadButton"
            >
              Files
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleButtonClick("folder")}
              className="fileUploadButton"
            >
              Folder
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept=".txt,.dat"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <input
        ref={folderInputRef}
        type="file"
        multiple
        webkitdirectory="true"
        accept=".txt,.dat"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <ul className="file-list">
        {uploadedFiles.map((file, index) => {
          let selected = false;
          for (let i = 0; i < selectedFiles.length; i++) {
            const selectedFile = selectedFiles[i];
            if (isDeepEqual(selectedFile, { file, index })) {
              selected = true;
              break;
            }
          }

          return (
            <li
              id="file-entry"
              key={index}
              onClick={(e) => handleFileClick(e, file, index)}
              className={selected ? "selected" : ""}
            >
              {file.dataPoints.length > 0 ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <div className="customTooltip">
                        <div className="title">First data point:</div>
                        <div className="data">
                          X:{file.dataPoints[0].x} | Y:{file.dataPoints[0].y}
                        </div>
                      </div>
                    </React.Fragment>
                  }
                >
                  <div className="fileName" id="file-entry-name">
                    {file.name}
                  </div>
                </HtmlTooltip>
              ) : (
                <div className="fileName" id="file-entry-name">
                  {file.name}
                </div>
              )}
              <DownloadIcon
                className="downloadFileIcon"
                onClick={() => handleDownload(file)}
              />
              <img
                className="deleteFileIcon"
                src={deleteIcon}
                alt={"detele icon"}
                onClick={(e) => {
                  handleDeleteClick(e, index);
                }}
                id="delete-list-entry-icon"
              />
            </li>
          );
        })}
      </ul>
      <Modal
        isOpen={fileProcessingOpen}
        onRequestClose={handleCloseProcessModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="File Processing Modal"
        appElement={fileInputRef.current}
        id="fileProcessModal"
        style={{
          content: {
            width: "80vw",
            height: "80vh",
            top: `10%`,
            left: "10%",
            right: "auto",
          },
          overlay: {
            zIndex: "8000",
          },
        }}
      >
        <FileProcessingWindow
          fileList={filesToProcess}
          cancelProcessing={handleCancelProcess}
          solveProcessing={handleSolveProcess}
          modalId={"fileProcessModal"}
        />
      </Modal>
      <Modal
        isOpen={filePresetsOpen}
        onRequestClose={handleClosePresetModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="File Option Preset Modal"
        appElement={presetOptionRef.current}
        id="fileOptionPresetModal"
        style={{
          content: {
            width: presetModalPlaceAndSize.width,
            height: presetModalPlaceAndSize.height,
            top: presetModalPlaceAndSize.top,
            left: presetModalPlaceAndSize.left,
            right: presetModalPlaceAndSize.right,
          },
          overlay: {
            zIndex: "8000",
          },
        }}
      >
        <div style={{ height: "100%" }}>
          <PresetLoader
            presetList={filePresets}
            deleteEntry={handleEntryDelete}
            optionMenu
            setPreset={handlePresetSelect}
            clearPreset={handlePresetClear}
            selectedPreset={selectedPreset}
          />
        </div>
      </Modal>
    </div>
  );
};

export default FileUploader;
