import React, { useState, useRef, useContext, useEffect } from "react";
import { useDrag } from "react-dnd";
import Plot from "react-plotly.js";
import { DashboardContext } from "../../context/DashboardContext";
import { GraphContext } from "../../context/GraphContext";
import { WebSocketContext } from "../../context/WebSocketContext";
import {
  getColor,
  isDeepEqual,
  getRepresentatives,
  generateWarningObject,
  hasProperty,
  findBestMatchingCurve,
  produceCurveObject,
  updateOrAddCurve,
  getShortName,
  useInputSize,
  findMinMaxY,
} from "../../utils/helpers";
import {
  deepCopy,
  getModelById,
  getModelsByIds,
  replaceModelById,
} from "../leftSide/Models/modelLogic";
import { Button } from "@mui/material";
import {
  createModelDistPayload,
  createModelList,
  createGraphModelEntry,
  generatePlotData,
  checkModelAndLoad,
} from "./graphLogic";
import "./middle.scss";
import { RAMAN_REF_ID } from "../../utils/constants";
import DOMPurify from "dompurify";
import AutoFitModal from "../commonComponents/AutoFitModal";
import ConfirmationScreen from "../commonComponents/ConfirmationScreen";
import OptionsModal from "./WindowComponents/OptionsModal";
// ICONS ICONS ICONS BELOW
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import SettingsIcon from "@mui/icons-material/Settings";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { debounce } from "lodash";
import graphPlaceholder from "../../res/images/graph_placeholder.png";
import { FileHandlingContext } from "../../context/FileHandlingContext";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import CurvesModal from "./WindowComponents/CurvesModal";
import { processFileWithOptions } from "../leftSide/Files/fileProcessing/processingLogic";
import { GeneralContext } from "../../context/GeneralContext";
import { AuthContext } from "../../context/AuthContext";

const DraggableGraphWindow = ({
  id,
  initialPosition,
  initialLayout,
  initialData,
  onDragStart,
  onDragEnd,
  zIndex,
  title,
  // largeWindow,
  smallWindow,
  deleteGraph,
  // isExpandedInitial,
  onGraphClick,
  ...props
}) => {
  const {
    uploadedFiles,
    modelData,
    setModelData,
    setWarnings,
    setNewWarningCount,
    setCleanupNeeded,
    // sectionsStatus,
    // setSectionsStatus,
    parametersOpen,
    setParametersOpen,
    // setShowLeftPanel,
    setCallFromGraph,
    setFileCallFromGraph,
    allLocalModels,
  } = useContext(DashboardContext);
  const { graphs, setGraphs } = useContext(GraphContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const { currentUser } = useContext(AuthContext);
  const { setSelectedFiles } = useContext(FileHandlingContext);
  // STATES:
  const [localTitle, setLocalTitle] = useState(title);
  const [position, setPosition] = useState(initialPosition);
  const [isExpanded, setIsExpanded] = useState(true);
  const {
    lastJsonMessage,
    sendJsonMessage,
    isFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
  } = useContext(WebSocketContext);
  const [delModalIsOpen, setDelModalIsOpen] = useState(false);
  const [selectFileList, setSelectFileList] = useState([]);
  const [selectModelList, setSelectModelList] = useState([]);
  const [graphData, setGraphData] = useState(initialData);
  const [graphLayout, setGraphLayout] = useState(initialLayout);
  const [distList, setDistList] = useState([]);

  // REFS:
  //using ref and updating it, because useEffect does not get the latest state variable from useState
  // const expandedRef = useRef(false);
  const windowDataIcons = useRef();
  const windowIcons = useRef();
  const isDraggingRef = useRef(false);
  const graphRef = useRef(graphs);
  const fileRef = useRef(uploadedFiles);
  const modelDataRef = useRef(deepCopy(modelData));
  // const requestedNewModelCurve = useRef(null);
  // const requestedModelUpdate = useRef(null);
  const graphsUpdatedFromCurves = useRef(null);

  // const currentSize = isExpanded ? largeWindow : smallWindow;

  // const [currentSize, setCurrentSize] = useState(
  //   isExpanded ? largeWindow : smallWindow
  // );

  const [currentSize, setCurrentSize] = useState(smallWindow);
  const [minimisedWidth, setMinimisedWidth] = useState(50);

  // Graph settings modal variables:
  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false);
  // -- Setting initial axis names for editing functionality and also sanitizing them from HTML elements
  // -- such as - <sup>
  const [xAxisName, setXAxisName] = useState(
    DOMPurify.sanitize(initialLayout.xaxis.title.text, { ALLOWED_TAGS: [] })
  );
  const [yAxisName, setYAxisName] = useState(
    DOMPurify.sanitize(initialLayout.yaxis.title.text, { ALLOWED_TAGS: [] })
  );
  const [xAxisRange, setXAxisRange] = useState("");
  const [xAxisRangeTemp, setXAxisRangeTemp] = useState("");
  const [yAxisRange, setYAxisRange] = useState("");
  const [yAxisRangeTemp, setYAxisRangeTemp] = useState("");
  const [isYAxisLog, setIsYAxisLog] = useState(false);
  const [isYAxisLogLast, setIsYAxisLogLast] = useState(false);

  // Curves modal variables:
  const [curvesModalIsOpen, setCurvesModalIsOpen] = useState();

  //Variables for Extra Section (for now just Raman AutoFit):
  // const [extraSection, setExtraSection] = useState(true);
  const [extraRaman, setExtraRaman] = useState(false);

  // Variables for AutoFit:
  const [autofitModalIsOpen, setAutofitModalIsOpen] = useState(false);
  const autoFitRef = useRef();
  const [ramanInGraph, setRamanInGraph] = useState(null);
  const [autoSelectedFile, setAutoSelectedFile] = useState(null);
  const [isResizing, setIsResizing] = useState(false);
  const [xAxisUpdatedInOptions, setXAxisUpdatedInOptions] = useState(false);

  useEffect(() => {
    return () => {
      debouncedSetSize.cancel();
    };
  }, []);

  useEffect(() => {
    if (xAxisUpdatedInOptions) {
      if (
        graphData.length > 0 &&
        graphData.some((dataEntry) => hasProperty(dataEntry, "modelId"))
      ) {
        const xAxisRange = {
          min: graphLayout.xaxis.range[0],
          max: graphLayout.xaxis.range[1],
        };
        handleRangesUpdate(xAxisRange);
      }

      setXAxisUpdatedInOptions(false);
    }
  }, [xAxisUpdatedInOptions]);

  // We call the cleanup only when the graphs got updated with latest ranges.
  useEffect(() => {
    if (graphsUpdatedFromCurves.current) {
      setCleanupNeeded(true);

      graphsUpdatedFromCurves.current = false;
    }
  }, [graphs]);

  useEffect(() => {
    const countOfFiles = distList.filter((dist) =>
      Object.prototype.hasOwnProperty.call(dist, "fileId")
    );
    if (countOfFiles.length === 1) {
      setAutoSelectedFile(
        uploadedFiles.find((file) => file.ID === countOfFiles[0].fileId)
      );
    } else {
      setAutoSelectedFile(null);
    }
  }, [distList]);

  useEffect(() => {
    graphRef.current = graphs;
    const currentGraph = graphRef.current.find((graph) => graph.id == id);
    if (
      currentGraph != undefined &&
      !isDeepEqual(currentGraph.plotData, graphData)
    ) {
      needsExtra(currentGraph.containedModels);
      setGraphData([...currentGraph.plotData]);

      // THIS IS DEMO ONLY, WILL PROBABLY MESS THINGS UP WITH GRAPH NAVIGATION FOR NON DEMO STUFF
      // BECAUSE LOCAL CHANGES SHOULD BE A PRIORITY
      // setGraphLayout(currentGraph.layout);
    }
  }, [graphs]);

  useEffect(() => {
    setDistList(getRepresentatives(graphData));
    const currentGraph = graphRef.current.find((graph) => graph.id == id);
    needsExtra(currentGraph.containedModels);
  }, [graphData]);

  // useEffect(() => {
  //   updateGraphSize(isExpanded);
  // }, [largeWindow, smallWindow]);

  function needsExtra(modelList) {
    if (modelList.length >= 1) {
      const idList = modelList.map((modelQuantity) => modelQuantity.modelId);
      let modelsInGraph = getModelsByIds(idList, modelData);
      let foundRaman = false;

      for (let i = 0; i < modelsInGraph.length; i++) {
        const model = modelsInGraph[i];
        if (model.reffitID === RAMAN_REF_ID) {
          foundRaman = true;
          setRamanInGraph(model);
        }
      }

      setExtraRaman(foundRaman);

      // Check if anything is found here - update if when more extras are added
      if (!foundRaman) {
        setExtrasFalse();
      } else {
        // setExtraSection(true);
      }
    } else {
      // set all extras here to false
      setExtrasFalse();
    }
  }

  function setExtrasFalse() {
    // setExtraSection(false);
    setExtraRaman(false);
  }

  const [, drag, preview] = useDrag(() => ({
    type: "WINDOW",
    item: { id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: () => {
      if (!isDraggingRef.current) {
        onDragStart(id);
        isDraggingRef.current = true;
      }
      return true;
    },
    end: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      setPosition((prevPosition) => ({
        x: prevPosition.x + delta.x,
        y: prevPosition.y + delta.y,
      }));
      onDragEnd();
      isDraggingRef.current = false;
    },
  }));

  function handleNewModelCurve(jsonMessage, details) {
    try {
      const foundData = jsonMessage.Model.SendCurve.find(
        (curve) =>
          curve.modelid == details.requestDetails.modelId &&
          curve.quantity == details.requestDetails.quantity
      );

      if (foundData != undefined) {
        handleModelDistAdd(foundData);
      } else {
        console.log("DATA NOT FOUND FOR MODEL CURVE");
      }
    } catch (error) {
      recordedErrorLog("Error handling new model curve response: ", error);
    }
  }

  function handleModelCurveUpdate(jsonMessage, details) {
    try {
      if (details.graphId === id) {
        const dataToUse = [];

        for (let i = 0; i < details.modelToUpdate.length; i++) {
          const requestedModelQuantSet = details.modelToUpdate[i];

          const foundData = jsonMessage.Model.SendCurve.find(
            (curve) =>
              curve.modelid == requestedModelQuantSet.modelId &&
              curve.quantity == requestedModelQuantSet.quantity
          );

          dataToUse.push(foundData);
        }

        handleGraphModelUpdate(dataToUse);
        deleteRequestDataEntry(jsonMessage.requestID);
      }
    } catch (error) {
      recordedErrorLog("Error handling model curve update response: ", error);
    }
  }

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "new-model-curve-request":
                if (requestData.graphId === id) {
                  handleNewModelCurve(lastJsonMessage, requestData);
                  deleteRequestDataEntry(lastJsonMessage.requestID);
                }
                break;
              case "model-curve-update":
                handleModelCurveUpdate(lastJsonMessage, requestData);
                break;
              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog("Error in model curve useEffect", error);
    }
  }, [lastJsonMessage]);

  const handleModelDistAdd = (data, curvesFromModel = false) => {
    try {
      const { modelid, coordinates, quantity } = data;
      const currentGraph = graphRef.current.find((graph) => graph.id == id);
      const modelAdded = getModelById(modelid, modelData);
      const graphColor = getColor(currentGraph.plotData.length + 1);

      let coordsToUse = coordinates;

      if (!curvesFromModel) {
        const curveObj = produceCurveObject(coordinates, quantity);
        modelAdded.curves = updateOrAddCurve(modelAdded.curves, curveObj);

        const newModels = replaceModelById(modelData, modelAdded, modelid);
        setModelData(newModels);

        const minNeed = currentGraph.layout.xaxis.range[0];
        const maxNeed = currentGraph.layout.xaxis.range[1];

        const foundCurve = findBestMatchingCurve(
          modelAdded.curves,
          minNeed,
          maxNeed,
          quantity
        );

        if (!foundCurve.bestFound) {
          const payload = createModelDistPayload(
            [modelAdded],
            { [modelAdded]: { [quantity]: [minNeed, maxNeed] } },
            [{ modelId: modelid, quantity: quantity }],
            currentUser
          );
          // requestedNewModelCurve.current = {
          //   modelId: modelAdded.FE_ID,
          //   quantity: quantity,
          // };
          sendJsonMessage(payload, {
            type: "new-model-curve-request",
            requestDetails: {
              modelId: modelAdded.FE_ID,
              quantity: quantity,
            },
            graphId: id,
          });
        }

        coordsToUse = foundCurve.curve.curve;
      }

      const shortName = getShortName(modelAdded.displayName);

      const plotData = generatePlotData(
        coordsToUse,
        "",
        "",
        graphColor,
        shortName,
        null,
        modelAdded.FE_ID,
        null,
        quantity
      );

      currentGraph.plotData = [...currentGraph.plotData, ...plotData];
      currentGraph.containedModels = [
        ...currentGraph.containedModels,
        {
          modelId: modelAdded.FE_ID,
          quantity: quantity,
        },
      ];

      // const size = expandedRef.current ? largeWindow : smallWindow;
      const size = currentSize;

      let localGraphs = deepCopy(graphRef.current);

      localGraphs = localGraphs.map((graph) => {
        if (graph.id == id) {
          return currentGraph;
        } else {
          return graph;
        }
      });

      setGraphs(localGraphs);

      // deepCopy is needed here, because the <Plot /> doesn't catch that the data is changed otherwise - probably
      // reference remains the same. This happened because there is now 'transition' added to plot layout
      setGraphData(deepCopy(currentGraph.plotData));
      setGraphLayout({
        ...currentGraph.layout,
        autosize: true,
        width: size.width - 10,
        height: size.height - 40,
      });
    } catch (error) {
      recordedErrorLog("model curve add handle failure: ", error);
    }
  };

  const handleGraphModelUpdate = (data) => {
    try {
      const currentGraph = graphRef.current.find((graph) => graph.id == id);
      let modelDataToUpdate = deepCopy(modelData);

      for (let i = 0; i < data.length; i++) {
        const { modelid, coordinates, quantity } = data[i];

        const modelUpdated = getModelById(modelid, modelDataToUpdate);
        const curveObj = produceCurveObject(coordinates, quantity);
        modelUpdated.curves = updateOrAddCurve(modelUpdated.curves, curveObj);
        modelDataToUpdate = replaceModelById(
          modelDataToUpdate,
          modelUpdated,
          modelid
        );

        let currentPlotData = {};
        const otherPlotData = [];

        for (let j = 0; j < currentGraph.plotData.length; j++) {
          const plot = currentGraph.plotData[j];

          if (
            hasProperty(plot, "modelId") &&
            plot.modelId === modelid &&
            plot.quantity === quantity
          ) {
            currentPlotData = plot;
          } else {
            otherPlotData.push(plot);
          }
        }

        const newPlotData = {
          ...currentPlotData,
          x: coordinates.map((point) => point.x),
          y: coordinates.map((point) => point.y),
        };

        currentGraph.plotData = [...otherPlotData, newPlotData];
      }

      setModelData(modelDataToUpdate);

      let localGraphs = deepCopy(graphRef.current);

      currentGraph.layout = graphLayout;

      localGraphs = localGraphs.map((graph) => {
        if (graph.id == id) {
          return currentGraph;
        } else {
          return graph;
        }
      });

      setGraphs(localGraphs);

      setGraphData(deepCopy(currentGraph.plotData));

      graphsUpdatedFromCurves.current = true;
    } catch (error) {
      recordedErrorLog("Graph model update handler failure: ", error);
    }
  };

  function handleModelClick(modelId, quantity) {
    try {
      const currentGraph = graphRef.current.find((graph) => graph.id === id);
      if (
        currentGraph !== undefined &&
        !currentGraph.containedModels.some(
          (modelQuantPair) =>
            modelId === modelQuantPair.modelId &&
            modelQuantPair.quantity === quantity
        )
      ) {
        const foundModel = getModelById(modelId, modelData);

        let minValueRequest = Math.floor(currentGraph.layout.xaxis.range[0]);
        let maxValueRequest = Math.ceil(currentGraph.layout.xaxis.range[1]);

        if (
          currentGraph.containedFiles.length === 0 &&
          currentGraph.containedModels.length === 0
        ) {
          const defaultModel = allLocalModels.find(
            (defMod) => defMod.reffit_id === foundModel.reffitID
          );

          if (defaultModel !== undefined) {
            minValueRequest = defaultModel.default_curve_min;
            maxValueRequest = defaultModel.default_curve_max;

            setGraphLayout((old) => {
              return {
                ...old,
                xaxis: {
                  ...old.xaxis,
                  range: [minValueRequest, maxValueRequest],
                },
              };
            });

            setGraphs((oldGraphs) => {
              return oldGraphs.map((graphOld) => {
                if (graphOld.id === id) {
                  graphOld.layout = {
                    ...graphOld.layout,
                    xaxis: {
                      ...graphOld.layout.xaxis,
                      range: [minValueRequest, maxValueRequest],
                    },
                  };
                }
                return graphOld;
              });
            });
          }
        }

        // const potentialCurve =
        checkModelAndLoad(
          foundModel,
          handleModelDistAdd,
          sendJsonMessage,
          minValueRequest,
          maxValueRequest,
          quantity,
          currentUser,
          { type: "new-model-curve-request", graphId: id }
        );

        // if (potentialCurve !== null) {
        //   requestedNewModelCurve.current = potentialCurve;
        // }
      } else {
        limitedToast("Graph already contains selected model-quantity pair.");
        generateWarningObject(
          "Graph already contains selected model-quantity pair.",
          1,
          setWarnings,
          setNewWarningCount
        );
      }
    } catch (error) {
      recordedErrorLog("Model click handler failure: ", error);
    }
  }

  useEffect(() => {
    if (!isDeepEqual(modelDataRef.current, modelData)) {
      modelDataRef.current = modelData;

      if (modelDataRef.current.length > 0) {
        setSelectModelList(
          createModelList(handleModelClick, modelData, createGraphModelEntry)
        );
      }
      if (modelDataRef.current.length === 0) {
        setSelectModelList([]);
      }
    }
  }, [modelData]);

  useEffect(() => {
    if (modelData.length > 0) {
      setSelectModelList(
        createModelList(handleModelClick, modelData, createGraphModelEntry)
      );
    }
  }, []);

  const updateGraphLayout = (layoutUpdate) => {
    try {
      const currentGraph = graphRef.current.find((graph) => graph.id == id);
      let localGraphs = deepCopy(graphRef.current);

      currentGraph.layout = { ...currentGraph.layout, ...layoutUpdate };

      localGraphs = localGraphs.map((graph) => {
        if (graph.id == id) {
          return currentGraph;
        } else {
          return graph;
        }
      });

      setGraphs(localGraphs);
      setGraphLayout(currentGraph.layout);
    } catch (error) {
      recordedErrorLog("Graph layout update failure: ", error);
    }
  };

  const updateGraphPlots = (plotsUpdate) => {
    try {
      const currentGraph = graphRef.current.find((graph) => graph.id == id);
      let localGraphs = deepCopy(graphRef.current);

      currentGraph.plotData = plotsUpdate;

      localGraphs = localGraphs.map((graph) => {
        if (graph.id == id) {
          return currentGraph;
        } else {
          return graph;
        }
      });

      setGraphs(localGraphs);
      setGraphData(plotsUpdate);
    } catch (error) {
      recordedErrorLog("Graph plot update failure: ", error);
    }
  };

  const handleFileSelected = (selectedId) => {
    try {
      const currentGraph = graphRef.current.find((graph) => graph.id == id);
      if (
        currentGraph !== undefined &&
        !currentGraph.containedFiles.some((id) => selectedId === id)
      ) {
        const dataToSet = fileRef.current.find((file) => file.ID == selectedId);
        const data = Object.prototype.hasOwnProperty.call(
          dataToSet,
          "dataPoints"
        )
          ? dataToSet.dataPoints
          : processFileWithOptions(
              dataToSet.content,
              dataToSet.options,
              recordedErrorLog
            );
        // getDataPointsFromFile(dataToSet.content);

        const graphColor = getColor(currentGraph.plotData.length + 1);

        const shortName = getShortName(dataToSet.name);

        const plotData = generatePlotData(
          data,
          dataToSet.dataRangeMin,
          dataToSet.dataRangeMax,
          graphColor,
          shortName,
          dataToSet.ID,
          null,
          dataToSet.edges,
          null
        );

        if (
          currentGraph.containedFiles.length === 0 &&
          currentGraph.containedModels.length === 0
        ) {
          const yRange = findMinMaxY(data);
          currentGraph.layout.xaxis.range = [
            dataToSet.dataRangeMin,
            dataToSet.dataRangeMax,
          ];
          currentGraph.layout.yaxis.range = [yRange.minY, yRange.maxY];
        }

        currentGraph.plotData = [...currentGraph.plotData, ...plotData];
        currentGraph.containedFiles = [
          ...currentGraph.containedFiles,
          dataToSet.ID,
        ];

        let localGraphs = deepCopy(graphRef.current);

        localGraphs = localGraphs.map((graph) => {
          if (graph.id == id) {
            return currentGraph;
          } else {
            return graph;
          }
        });

        setGraphs(localGraphs);

        // deepCopy is needed here, because the <Plot /> doesn't catch that the data is changed otherwise - probably
        // reference remains the same. This happened because there is now 'transition' added to plot layout
        setGraphData(deepCopy(currentGraph.plotData));
      } else {
        limitedToast("Graph already contains selected file.");
        generateWarningObject(
          "Graph already contains selected file.",
          1,
          setWarnings,
          setNewWarningCount
        );
      }
    } catch (error) {
      recordedErrorLog("Selected file handler failure: ", error);
    }
  };

  useEffect(() => {
    fileRef.current = uploadedFiles;
    if (uploadedFiles.length > 0) {
      const fileList = uploadedFiles.map((file) => {
        return (
          <div
            key={file.ID}
            className="fileEntry"
            onClick={() => handleFileSelected(file.ID)}
          >
            {file.name}
          </div>
        );
      });

      setSelectFileList(fileList);
    }
  }, [uploadedFiles]);

  const updateGraphOnResize = () => {
    try {
      const currentGraph = graphRef.current.find((graph) => graph.id == id);

      const newWidth = currentSize.width;
      const newHeight = currentSize.height;

      graphRef.current = graphRef.current.map((graph) => {
        if (graph.id == id) {
          return {
            ...graph,
            layout: {
              ...currentGraph.layout,
              width: newWidth - 10,
              height: newHeight - 40,
            },
          };
        } else {
          return graph;
        }
      });

      setGraphs(graphRef.current);

      setGraphLayout({
        ...currentGraph.layout,
        width: newWidth - 10,
        height: newHeight - 40,
      });
    } catch (error) {
      recordedErrorLog("Graph update on resize failure: ", error);
    }
  };

  useEffect(() => {
    updateGraphOnResize();
  }, [currentSize]);

  const debouncedSetSize = debounce((newSize) => {
    setCurrentSize(newSize);
  }, 50); // 100ms debounce time, adjust as needed

  const handleResizeStart = () => {
    if (isExpanded) {
      setIsResizing(true);
    }
  };

  const handleResizeStop = (event, { size }) => {
    setCurrentSize({ width: size.width, height: size.height });
    updateGraphOnResize();
    setTimeout(() => {
      setIsResizing(false);
    }, 10);
  };

  const handleDistDelete = (dist) => {
    try {
      let updatedGraphData = [];
      if (hasProperty(dist, "fileId")) {
        // We are dealing with a file here
        updatedGraphData = graphData.filter((plot) => {
          if (hasProperty(plot, "fileId")) {
            if (plot.fileId === dist.fileId) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        });

        setGraphData(updatedGraphData);
      } else {
        // We are dealing with a model here
        updatedGraphData = graphData.filter((plot) => {
          if (hasProperty(plot, "modelId")) {
            if (
              plot.modelId === dist.modelId &&
              plot.quantity === dist.quantity
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        });
      }

      setGraphData(updatedGraphData);

      graphRef.current = graphRef.current.map((graph) => {
        if (graph.id == id) {
          if (hasProperty(dist, "fileId")) {
            return {
              ...graph,
              plotData: updatedGraphData,
              containedFiles: updatedGraphData
                .filter((plot) => hasProperty(plot, "fileId"))
                .map((plot) => plot.fileId),
            };
          } else {
            return {
              ...graph,
              plotData: updatedGraphData,
              containedModels: updatedGraphData
                .filter((plot) => hasProperty(plot, "modelId"))
                .map((plot) => {
                  return { modelId: plot.modelId, quantity: plot.quantity };
                }),
            };
          }
        } else {
          return graph;
        }
      });

      setGraphs(graphRef.current);
    } catch (error) {
      recordedErrorLog("Curve deletion handler failure: ", error);
    }
  };

  // Settings window functions START VVVVVVVVVVVVVVVVVVVVVV

  const handleSettings = () => {
    try {
      if (
        graphLayout !== undefined &&
        graphLayout.xaxis !== undefined &&
        graphLayout.yaxis !== undefined &&
        graphLayout.xaxis.range !== undefined &&
        graphLayout.yaxis.range !== undefined
      ) {
        setXAxisRange({
          min: graphLayout.xaxis.range[0],
          max: graphLayout.xaxis.range[1],
        });
        setXAxisRangeTemp({
          min: graphLayout.xaxis.range[0],
          max: graphLayout.xaxis.range[1],
        });
        setYAxisRange({
          min: graphLayout.yaxis.range[0],
          max: graphLayout.yaxis.range[1],
        });
        setYAxisRangeTemp({
          min: graphLayout.yaxis.range[0],
          max: graphLayout.yaxis.range[1],
        });
      }
      setSettingsModalIsOpen(true);
    } catch (error) {
      recordedErrorLog("Setting handler failure: ", error);
    }
  };

  const handleGraphDelete = () => {
    deleteGraph(id);
    setDelModalIsOpen(false);
  };

  const handleGraphDeleteIcon = () => {
    setDelModalIsOpen(true);
  };

  // Settings window functions END ^^^^^^^^^^^^^^^^^^^^^^^^

  // Curves Window functions START VVVVVVVVVVVVVVVVVVV

  const handleCurvesOptOpen = () => {
    setCurvesModalIsOpen(true);
  };

  // Curves Window functions END ^^^^^^^^^^^^^^^^^^^^^^

  // AutoFit Functionality starts: VVVVVVVVVVVVVVVVV

  const handleRamanAutofit = () => {
    if (uploadedFiles.length > 0) {
      setAutofitModalIsOpen(true);
    } else {
      limitedToast(
        "There needs to be at least 1 file uploaded for AutoFit to function."
      );
      generateWarningObject(
        "There needs to be at least 1 file uploaded for AutoFit to function.",
        0,
        setWarnings,
        setNewWarningCount
      );
    }
  };

  // AutoFit Functionality ends ^^^^^^^^^^^^^^^^^^^^

  const handleRelayout = (eventData) => {
    try {
      // Extracting new x and y ranges
      let xAxisRange = {};

      if (
        hasProperty(eventData, "xaxis.autorange") &&
        eventData["xaxis.autorange"]
      ) {
        xAxisRange = {
          min: graphLayout.xaxis.range[0],
          max: graphLayout.xaxis.range[1],
        };
      } else if (hasProperty(eventData, "xaxis.range[0]")) {
        xAxisRange = {
          min: eventData["xaxis.range[0]"],
          max: eventData["xaxis.range[1]"],
        };
      } else if (hasProperty(eventData, "xaxis.range")) {
        xAxisRange = {
          min: eventData["xaxis.range"][0],
          max: eventData["xaxis.range"][1],
        };
      }

      // We need to check this, because sometimes only y axis can be updated and we don't need to do anything
      // when that happens
      if (hasProperty(xAxisRange, "min")) {
        handleRangesUpdate(xAxisRange);
      }
    } catch (error) {
      recordedErrorLog("Relayout handler failure: ", error);
    }
  };

  function handleRangesUpdate(xAxisRange) {
    const currentGraph = graphRef.current.find((graph) => graph.id == id);

    const graphModels = currentGraph.containedModels;

    if(graphModels.length > 0){
      const justModels = graphModels.map((modelQuant) => modelQuant.modelId);
  
      const modelsToUpdate = getModelsByIds(justModels, modelData);
  
      let rangesToSend = {};
  
      for (let i = 0; i < graphModels.length; i++) {
        const quantityModelSet = graphModels[i];
  
        if (hasProperty(rangesToSend, quantityModelSet.modelId)) {
          if (
            hasProperty(
              rangesToSend[quantityModelSet.modelId],
              quantityModelSet.quantity
            )
          ) {
            rangesToSend = {
              ...rangesToSend,
              [quantityModelSet.modelId]: {
                [quantityModelSet.quantity]: [
                  ...rangesToSend[quantityModelSet.modelId][
                    quantityModelSet.quantity
                  ],
                  [xAxisRange.min, xAxisRange.max],
                ],
              },
            };
          } else {
            rangesToSend = {
              ...rangesToSend,
              [quantityModelSet.modelId]: {
                ...rangesToSend[quantityModelSet.modelId],
                [quantityModelSet.quantity]: [[xAxisRange.min, xAxisRange.max]],
              },
            };
          }
        } else {
          rangesToSend = {
            ...rangesToSend,
            [quantityModelSet.modelId]: {
              [quantityModelSet.quantity]: [[xAxisRange.min, xAxisRange.max]],
            },
          };
        }
      }
  
      const payloadForModelUpdate = createModelDistPayload(
        modelsToUpdate,
        rangesToSend,
        graphModels,
        currentUser
      );
  
      const layoutCopy = deepCopy(graphLayout);
  
      setGraphLayout({
        ...layoutCopy,
        xaxis: {
          ...layoutCopy.xaxis,
          range: [xAxisRange.min, xAxisRange.max],
        },
      });
  
      sendJsonMessage(payloadForModelUpdate, {
        type: "model-curve-update",
        modelToUpdate: graphModels,
        graphId: id,
      });
    }
  }

  // Custom Plot legend intereactions
  const handleLegendClick = (event) => {
    try {
      if (hasProperty(event.data[event.curveNumber], "modelId")) {
        if (!parametersOpen) {
          // setSectionsStatus((old) => {
          //   return {
          //     ...old,
          //     paramOpen: true,
          //   };
          // });
          setParametersOpen(true);
          // setShowLeftPanel(false);
          setCallFromGraph(true);
        }
      } else {
        let selectedFile = {};
        let selectedFileIndex = -1;

        for (let i = 0; i < uploadedFiles.length; i++) {
          const file = uploadedFiles[i];
          if (file.ID === event.data[event.curveNumber].fileId) {
            selectedFile = file;
            selectedFileIndex = i;
          }
        }

        setFileCallFromGraph(true);
        setSelectedFiles([{ file: selectedFile, index: selectedFileIndex }]);
      }

      // Return false to prevent Plotly's default legend click behavior
      return false;
    } catch (error) {
      recordedErrorLog("Legend click handler failure: ", error);
    }
  };

  const handleTitleClick = () => {
    try {
      const newTitle = prompt("Enter a new graph title", localTitle);
      if (newTitle !== null) {
        const currentGraph = graphs.find(
          (graphToFind) => graphToFind.id === id
        );
        currentGraph.title = newTitle;
        setLocalTitle(newTitle);
        setGraphs((old) =>
          old.map((oldGraph) => {
            if (oldGraph.id === id) {
              return currentGraph;
            } else {
              return oldGraph;
            }
          })
        );
      }
    } catch (error) {
      recordedErrorLog("Title click handler failure: ", error);
    }
  };

  useEffect(() => {
    if (localTitle) {
      setMinimisedWidth(useInputSize(localTitle) + 100);
    }
  }, [localTitle]);

  const handleMinimise = () => {
    setMinimisedWidth(useInputSize(localTitle) + 100);
    setIsExpanded(false);
  };

  const handleMaximise = () => {
    setIsExpanded(true);
  };

  return (
    <ResizableBox
      width={isExpanded ? currentSize.width : minimisedWidth}
      // height={extraSection ? currentSize.height + 40 : currentSize.height}
      height={isExpanded ? currentSize.height : 35}
      // minConstraints={[100, 100]}
      minConstraints={
        isExpanded
          ? [Math.max(window.innerWidth * 0.2, 270), window.innerHeight * 0.2]
          : [minimisedWidth, 35]
      }
      maxConstraints={[window.innerWidth * 0.7, window.innerHeight * 0.8]}
      style={{
        left: position.x,
        top: position.y,
        zIndex: zIndex + 105,
        position: "absolute",
      }}
      onResize={(event, { size }) => {
        if (isExpanded) {
          debouncedSetSize({ width: size.width, height: size.height });
        }
      }}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      // onResize={(event, { size }) => {
      //   setCurrentSize((prevSize) => ({
      //     width: size.width,
      //     height: size.height,
      //   }));
      // }}
      resizeHandles={isExpanded ? ["se"] : []} // Conditional handles
      className="grapWindowResizableBox"
      onClick={() => onGraphClick(id)}
    >
      <div
        ref={preview}
        style={{
          // left: position.x,
          // top: position.y,
          // width: currentSize.width,
          // height: extraSection ? currentSize.height + 40 : currentSize.height,
          width: "100%",
          height: "100%",
          zIndex: zIndex + 100,
        }}
        className="draggableWindow"
        id="draggable-graph-window"
        {...props}
      >
        <div ref={drag} className="dragHandler">
          {isExpanded ? (
            <>
              <div className="dataIcons" ref={windowDataIcons}>
                <SettingsIcon
                  className="settingsIcon"
                  id="graph-settings-icon"
                  onClick={() => {
                    if (!isFitOngoing) {
                      handleSettings();
                    }
                  }}
                />
                <SsidChartIcon
                  className="curvesIcon"
                  id="graph-curves-icon"
                  onClick={() => {
                    if (!isFitOngoing) {
                      handleCurvesOptOpen();
                    }
                  }}
                />
                {extraRaman ? (
                  <div className="ramanExtras">
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ m: 1 }}
                      onClick={() => handleRamanAutofit()}
                      className="ramanAutofit"
                      id="autofit-raman-graph-button"
                      disabled={isFitOngoing}
                    >
                      AutoFit
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          <div
            className="windowTitle"
            id="graph-window-title"
            onClick={() => handleTitleClick()}
          >
            {localTitle}
          </div>
          <div className="dragWinManageIcons" ref={windowIcons}>
            {isExpanded ? (
              <CloseFullscreenIcon
                className="sizeIcon"
                onClick={() => handleMinimise()}
                id="minimise-graph-window-icon"
              />
            ) : (
              <OpenInFullIcon
                className="sizeIcon"
                onClick={() => handleMaximise()}
                id="maximise-graph-window-icon"
              />
            )}
            <div
              className="deleteGraphButton"
              onClick={() => handleGraphDeleteIcon()}
              id="delete-graph-button"
            >
              <HighlightOffIcon className="deleteGraphIcon" />
            </div>
          </div>
        </div>
        {isExpanded ? (
          <>
            <div
              className="dragContainer"
              ref={autoFitRef}
              id="dragrable-graph-plot-container"
            >
              <div
                className="plotWrapper"
                style={{ width: "100%", height: "100%" }}
              >
                {isResizing ? (
                  <img
                    className="plotPlaceholder"
                    src={graphPlaceholder}
                    alt="Resizing ..."
                  />
                ) : (
                  <Plot
                    data={graphData}
                    layout={graphLayout}
                    onRelayout={handleRelayout}
                    onLegendClick={handleLegendClick}
                  />
                )}
              </div>
              <AutoFitModal
                model={ramanInGraph}
                reference={autoFitRef}
                autofitModalIsOpen={autofitModalIsOpen}
                setAutofitModalIsOpen={setAutofitModalIsOpen}
                preSelectedFile={autoSelectedFile}
              />
            </div>
            <OptionsModal
              settingsModalIsOpen={settingsModalIsOpen}
              setSettingsModalIsOpen={setSettingsModalIsOpen}
              yAxisName={yAxisName}
              setYAxisName={setYAxisName}
              xAxisName={xAxisName}
              setXAxisName={setXAxisName}
              isYAxisLogLast={isYAxisLogLast}
              setIsYAxisLogLast={setIsYAxisLogLast}
              isYAxisLog={isYAxisLog}
              setIsYAxisLog={setIsYAxisLog}
              xAxisRange={xAxisRange}
              xAxisRangeTemp={xAxisRangeTemp}
              setXAxisRangeTemp={setXAxisRangeTemp}
              yAxisRange={yAxisRange}
              yAxisRangeTemp={yAxisRangeTemp}
              setYAxisRangeTemp={setYAxisRangeTemp}
              reference={windowDataIcons}
              initialLayout={initialLayout}
              graphRef={graphRef}
              id={id}
              updateGraphLayout={updateGraphLayout}
              zIndex={zIndex}
              setXAxisUpdated={setXAxisUpdatedInOptions}
            />
            <CurvesModal
              modelData={modelData}
              curvesModalIsOpen={curvesModalIsOpen}
              setCurvesModalIsOpen={setCurvesModalIsOpen}
              reference={windowDataIcons}
              distList={distList}
              handleDistDelete={handleDistDelete}
              selectFileList={selectFileList}
              selectModelList={selectModelList}
              graphRef={graphRef}
              id={id}
              updateGraphPlots={updateGraphPlots}
              zIndex={zIndex}
            />
          </>
        ) : (
          <></>
        )}
        <ConfirmationScreen
          onYes={() => handleGraphDelete()}
          onNo={() => setDelModalIsOpen(false)}
          text={"Are you sure you want to remove this graph?"}
          open={delModalIsOpen}
          setOpen={setDelModalIsOpen}
          element={windowIcons}
        />
      </div>
    </ResizableBox>
  );
};

export default DraggableGraphWindow;
