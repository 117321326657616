import React, {
  createContext,
  useState,
  useContext,
  // useReducer,
  // useEffect,
  useRef,
} from "react";
import useWebSocket from "react-use-websocket";
import { AuthContext } from "./AuthContext";
import { DashboardContext } from "./DashboardContext";
import { GeneralContext } from "./GeneralContext";
import { djb2Hash, generateWarningObject, hasProperty } from "../utils/helpers";

export const WebSocketContext = createContext();

const DELAY_TIME = 10; // 5 milliseconds
const MAX_RECONNECT_ATTEMPTS = 5; // Maximum reconnection attempts

const WebSocketContextProvider = (props) => {
  const [authSent, setAuthSent] = useState(false);
  //web socket is in a global context, so that it's opened and closed only once
  const { currentUser, isAuthReady, baseBE_URL } = useContext(AuthContext);
  const { setWarnings, setNewWarningCount } = useContext(DashboardContext);
  const { limitedToast } = useContext(GeneralContext);
  const [isFitOngoing, setIsFitOngoing] = useState(false);
  const reconnectAttempts = useRef(0);
  const requestIndex = useRef(0);
  const requestDict = useRef({});

  // const messageReducer = (state, action) => {
  //   if (action.type === "ADD_MESSAGE") {
  //     return [...state, action.payload];
  //   } else if (action.type === "CLEAR_MESSAGES") {
  //     return [];
  //   } else {
  //     return state; // No change for unhandled actions
  //   }
  // };

  // const [messages, dispatchMessage] = useReducer(messageReducer, []);
  // const [lastProcessedIndex, setLastProcessedIndex] = useState(-1);
  const [delayedLastJsonMessage, setDelayedLastJsonMessage] = useState(null);
  const [delayedLastMessage, setDelayedLastMessage] = useState(null);
  // const [timer, setTimer] = useState(null);

  // const timeQueue = useRef([]);
  const firstConnection = useRef(true);

  const idOfUser =
    isAuthReady && currentUser && currentUser.id ? currentUser.id : -1;

  // const webSocURL = isAuthReady ? `${baseBE_URL}/${idOfUser}` : null;
  const webSocURL =
    isAuthReady && currentUser && currentUser.id
      ? `${baseBE_URL}/${idOfUser}`
      : null;

  // Initialize a message queue to store incoming messages
  const messageQueue = useRef([]);

  // Flag to indicate if the system is currently processing messages
  const isProcessing = useRef(false);

  // Function to process the queue of messages one by one
  const processQueue = () => {
    if (isProcessing.current || messageQueue.current.length === 0) {
      return; // If already processing or no messages, return
    }

    // Set processing flag to true to block other messages from starting until done
    isProcessing.current = true;

    // Process each message in the queue
    const message = messageQueue.current.shift(); // Get the first message in the queue

    if (message) {
      try {
        const parsedMessage = JSON.parse(message);
        setDelayedLastJsonMessage(parsedMessage);
      } catch (_) {
        // Not a JSON message
        setDelayedLastMessage(message);
      }

      // Once processed, check if there are more messages to process
      setTimeout(() => {
        isProcessing.current = false; // Done processing, allow the next message
        processQueue(); // Check for any other messages in the queue
      }, DELAY_TIME); // Delay between message processing
    }
  };

  // On receiving a message from WebSocket, push it into the queue
  const handleMessage = (event) => {
    messageQueue.current.push(event.data); // Add new message to the queue
    processQueue(); // Start processing the queue
  };

  // UNCOMMEN IF MESSAGE QUEUE NEEDS CLEAN UP:
  // const clearResponseMessages = () => {
  //   messageQueue.current = []; // Clear the queue
  //   isProcessing.current = false; // Reset the processing flag
  //   setLastProcessedIndex(-1);
  //   setDelayedLastJsonMessage(null);
  //   setDelayedLastMessage(null);
  // };

  function resetWebSocketContext() {
    setAuthSent(false);
    setIsFitOngoing(false);
  }

  // const getDelay = () => {
  //   const currentTime = Date.now();
  //   if (timeQueue.current.length === 0) {
  //     // there are no messages in queue, so no need for the delay
  //     const timeAfterDelay = currentTime;

  //     const delayObject = {
  //       start: currentTime,
  //       trigger: timeAfterDelay,
  //     };

  //     timeQueue.current.push(delayObject);

  //     return delayObject;
  //   }

  //   // If we get here, that means the queue is not empty and we have to pick delay carefully
  //   let objectWithLargestTrigger = timeQueue.current.reduce((max, obj) =>
  //     obj.trigger > max.trigger ? obj : max
  //   );

  //   let timeAfterDelay = null;

  //   if (objectWithLargestTrigger.trigger > currentTime + DELAY_TIME) {
  //     timeAfterDelay = objectWithLargestTrigger.trigger + DELAY_TIME;
  //   } else {
  //     timeAfterDelay = currentTime + DELAY_TIME;
  //   }

  //   const delayObject = {
  //     start: currentTime,
  //     trigger: timeAfterDelay,
  //   };

  //   timeQueue.current.push(delayObject);

  //   return delayObject;
  // };

  // const handleDelayOver = (delayObject) => {
  //   timeQueue.current = timeQueue.current.filter(
  //     (object) =>
  //       object.start !== delayObject.start &&
  //       object.trigger !== delayObject.trigger
  //   );
  // };


  // const clearMessages = () => {
  //   dispatchMessage({ type: "CLEAR_MESSAGES" });
  //   setLastProcessedIndex(-1); // Reset the last processed index
  //   // Clear any delayed messages if necessary
  //   setDelayedLastJsonMessage(null);
  //   setDelayedLastMessage(null);
  // };

  const {
    sendMessage,
    sendJsonMessage,
    // lastMessage,
    // lastJsonMessage,
    readyState,
  } = useWebSocket(webSocURL, {
    shouldReconnect: () => {
      if (reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
        reconnectAttempts.current = reconnectAttempts.current + 1;
        return true;
      }
      limitedToast(`Server connection issue.`);
      generateWarningObject(
        `The connection to the server was lost and reconnects were unsuccessful.` +
          ` Please save your work and try to log in again. If that doesn't work, contact support: contact@speqqle.com`,
        2,
        setWarnings,
        setNewWarningCount
      );
      return false;
    }, // function can get param 'closeEvent'
    share: true, // Share a single WebSocket connection across hooks
    // filter: () => isAuthReady,
    filter: () => isAuthReady && currentUser && currentUser.id,
    // onMessage: (event) =>
    //   dispatchMessage({ type: "ADD_MESSAGE", payload: event.data }),
    onMessage: handleMessage, // Use the queue-based handler for messages
    onOpen: () => {
      if (firstConnection.current) {
        // This is a first connection to the websocket, so we do nothing but mark that the first connection was done
        firstConnection.current = false;
      } else {
        // This is a reconnection, so we need to reset some variables, namely the ones responsible for websocket
        // authentification
        resetWebSocketContext();
        reconnectAttempts.current = 0;
      }
    },
  });

  function generateRequestId() {
    const stringToHash = `${Date.now()}|${requestIndex.current}|${
      currentUser.id
    }`;

    const hashedID = djb2Hash(stringToHash);

    requestIndex.current = requestIndex.current + 1;

    return hashedID;
  }

  function regenerateId(currentId) {
    const LIMIT = 10;

    for (let i = 0; i < LIMIT; i++) {
      let newId = generateRequestId();

      if (newId !== currentId) {
        return newId;
      }
    }

    limitedToast(`Communication issue`);
    generateWarningObject(
      `Issue with producing request id, please report to support.`,
      2,
      setWarnings,
      setNewWarningCount
    );

    return null;
  }

  function checkedSendJsonMessage(message, details = {}) {
    if (!isFitOngoing || hasProperty(message, "stopfit")) {
      let idToSend = generateRequestId();

      if (hasProperty(requestDict.current, idToSend)) {
        const regeneratedId = regenerateId(idToSend);

        if (regeneratedId === null) {
          return;
        }

        idToSend = regeneratedId;
      }

      if (details) {
        requestDict.current[idToSend] = details;
      }

      // console.log("--------------------------------------------");
      // console.log("Request sent with id: ", idToSend);
      // console.log("Recorded details for request: ", details);
      // console.log("--------------------------------------------");

      sendJsonMessage({
        requestID: idToSend,
        ...message,
      });
    } else {
      limitedToast(
        `The fit is still processing, all other requests are not allowed during this period.`
      );
      generateWarningObject(
        `The fit is still processing, all other requests are not allowed during this period.`,
        2,
        setWarnings,
        setNewWarningCount
      );
    }
  }

  function retrieveRequestData(id) {
    if (hasProperty(requestDict.current, id)) {
      return requestDict.current[id];
    }
    return null;
  }

  function deleteRequestDataEntry(id) {
    if (hasProperty(requestDict.current, id)) {
      delete requestDict.current[id];
    }
  }

  // useEffect(() => {
  //   if (messages.length > 0) {
  //     // Clear previous timer
  //     if (timer) {
  //       clearTimeout(timer);
  //     }

  //     // Set a new timer
  //     const newTimer = setTimeout(() => {
  //       clearMessages();
  //     }, 1000); // 100 milliseconds

  //     setTimer(newTimer);
  //   }
  // }, [messages]);

  // useEffect(() => {
  //   // Whenever a new message is added to 'messages', update 'delayedLastJsonMessage' after a delay
  //   if (messages.length > 0 && lastProcessedIndex < messages.length - 1) {
  //     const localLastMessage = messages[lastProcessedIndex + 1];

  //     if (localLastMessage) {
  //       const delayObject = getDelay();

  //       const delay = delayObject.trigger - delayObject.start;

  //       try {
  //         const parsedMessage = JSON.parse(localLastMessage);
  //         setTimeout(() => {
  //           setDelayedLastJsonMessage(parsedMessage);
  //           handleDelayOver(delayObject);
  //         }, delay);
  //       } catch (_) {
  //         // Not a JSON message
  //         setTimeout(() => {
  //           setDelayedLastMessage(localLastMessage);
  //           handleDelayOver(delayObject);
  //         }, delay);
  //       }

  //       setLastProcessedIndex(lastProcessedIndex + 1);
  //     }
  //   }
  // }, [messages, lastProcessedIndex]);

  const value = {
    lastJsonMessage: delayedLastJsonMessage,
    lastMessage: delayedLastMessage,
    sendJsonMessage: checkedSendJsonMessage,
    sendMessage,
    readyState,
    authSent,
    setAuthSent,
    resetWebSocketContext,
    isFitOngoing,
    setIsFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {props.children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContextProvider;
