import React, { useContext } from "react";
import { DashboardContext } from "../../context/DashboardContext";
import "./windowSelector.scss";

function WindowSelector() {
  const {
    demoOpen,
    setDemoOpen,
    dataOpen,
    setDataOpen,
    modelsOpen,
    setModelsOpen,
    parametersOpen,
    setParametersOpen,
    fitOpen,
    setFitOpen,
    modelData,
  } = useContext(DashboardContext);

  return (
    <div className="windowSelector">
      <div className="switchPlatter" />
      <div className="switches">
        <div
          className={`switch${demoOpen ? " open" : ""}`}
          onClick={() => setDemoOpen((old) => !old)}
          id="demo-switch"
        >
          Demo
        </div>
        <div
          className={`switch${dataOpen ? " open" : ""}`}
          onClick={() => setDataOpen((old) => !old)}
          id="data-switch"
        >
          Data
        </div>
        <div
          className={`switch${modelsOpen ? " open" : ""}`}
          onClick={() => setModelsOpen((old) => !old)}
          id="models-switch"
        >
          Models
        </div>
        <div
          className={`switch${parametersOpen ? " open" : ""}${
            modelData.length > 0 ? "" : " disabled"
          }`}
          onClick={() =>
            modelData.length > 0
              ? setParametersOpen((old) => !old)
              : setParametersOpen(false)
          }
          id="parameters-switch"
        >
          Parameters
        </div>
        <div
          className={`switch${fitOpen ? " open" : ""}`}
          onClick={() => setFitOpen((old) => !old)}
          id="fit-switch"
        >
          Fit
        </div>
      </div>
    </div>
  );
}

export default WindowSelector;
